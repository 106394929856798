<template>
<items-order-provider
  v-bind="{ ...$attrs, items: value }"
  v-on="$listeners"
  v-slot="{ prev, next }"
>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Специальность
          </th>
          <th class="text-left"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item, idx in value"
          :key="item.id"
        >
          <td style="width: 100px">
            <order-arrows
              @prev="() => prev(idx)"
              @next="() => next(idx)"
            />
          </td>
          <td>{{ item.name }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</items-order-provider>
</template>

<script>
import ItemsOrderProvider from '@/components/ItemsOrderProvider.vue'
import OrderArrows from '@/components/OrderArrows.vue'

export default {
  components: {
    ItemsOrderProvider,
    OrderArrows,
  },
  props: {
    value: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>
