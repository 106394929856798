<template>
  <div class="cg">
    <image-requirements size="2256px" class="mb-4" />
    <list-editor-light
      :dialog-options="{ maxWidth: 500 }"
      v-model="items"
      @input="$emit('input', $event)"
    >
      <template #content="{ item }">
        <div class="cg__item">
          <img class="cg__item-photo" :src="item.photo.thumbnailUrl" v-if="item.photo">
          <span class="cg__item-title" v-if="item.title">{{ item.title.ru }}</span>
        </div>
      </template>
      <template #dialog="{ model, input }">
        <blocks-editor-field-gallery-item :value="model" @input="input" />
      </template>
    </list-editor-light>
  </div>
</template>

<script>
import ListEditorLight from '@/components/ListEditor/ListEditorLight.vue'
import BlocksEditorFieldGalleryItem from './BlocksEditorFieldGalleryItem.vue'

export default {
  components: {
    ListEditorLight,
    BlocksEditorFieldGalleryItem
  },
  props: {
    value: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      items: this.value
    }
  },
}
</script>

<style lang="scss" scoped>
.cg__item {
  display: flex;
  align-items: center;
  grid-gap: 12px;
}

.cg__item-photo {
  width: 120px;
  height: 80px;
  display: block;
  object-fit: cover;
}

.cg__item-title {

}
</style>
